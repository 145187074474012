@font-face {
    font-family: 'Crimson Text Regular';
    src:
        url('../../fonts/CrimsonText-Regular.woff2') format('woff2'),
        url('../../fonts/CrimsonText-Regular.woff') format('woff'),
        url('../../fonts/CrimsonText-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'text-security-disc';
    src: url('../../fonts/text-security-disc.eot');
    src:
        url('../../fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/text-security-disc.woff2') format('woff2'),
        url('../../fonts/text-security-disc.woff') format('woff'),
        url('../../fonts/text-security-disc.ttf') format('truetype'),
        url('../../fonts/text-security-disc.svg#text-security') format('svg');
}

@mixin sprig-font-face($font-family, $font-weight, $font-style, $file-path) {
    @font-face {
        font-family: '#{$font-family}';
        src:
            url('#{$file-path}.woff2') format('woff2'),
            url('#{$file-path}.otf') format('opentype');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

// Sprig
@include sprig-font-face('Sprig', 100, normal, '../../fonts/FAIRE-Sprig-Hairline');
@include sprig-font-face('Sprig', 100, italic, '../../fonts/FAIRE-Sprig-HairlineItalic');
@include sprig-font-face('Sprig', 200, normal, '../../fonts/FAIRE-Sprig-Thin');
@include sprig-font-face('Sprig', 200, italic, '../../fonts/FAIRE-Sprig-ThinItalic');
@include sprig-font-face('Sprig', 300, normal, '../../fonts/FAIRE-Sprig-Light');
@include sprig-font-face('Sprig', 300, italic, '../../fonts/FAIRE-Sprig-LightItalic');
@include sprig-font-face('Sprig', 400, normal, '../../fonts/FAIRE-Sprig-Regular');
@include sprig-font-face('Sprig', 400, italic, '../../fonts/FAIRE-Sprig-RegularItalic');
@include sprig-font-face('Sprig', 500, normal, '../../fonts/FAIRE-Sprig-Medium');
@include sprig-font-face('Sprig', 500, italic, '../../fonts/FAIRE-Sprig-MediumItalic');
@include sprig-font-face('Sprig', 600, normal, '../../fonts/FAIRE-Sprig-Bold');
@include sprig-font-face('Sprig', 600, italic, '../../fonts/FAIRE-Sprig-BoldItalic');
@include sprig-font-face('Sprig', 800, normal, '../../fonts/FAIRE-Sprig-Black');
@include sprig-font-face('Sprig', 800, italic, '../../fonts/FAIRE-Sprig-BlackItalic');
@include sprig-font-face('Sprig', 900, normal, '../../fonts/FAIRE-Sprig-Super');
@include sprig-font-face('Sprig', 900, italic, '../../fonts/FAIRE-Sprig-SuperItalic');

// SprigSans
@include sprig-font-face('SprigSans', 100, normal, '../../fonts/FAIRE-SprigSans-Hairline');
@include sprig-font-face('SprigSans', 100, italic, '../../fonts/FAIRE-SprigSans-HairlineItalic');
@include sprig-font-face('SprigSans', 200, normal, '../../fonts/FAIRE-SprigSans-Thin');
@include sprig-font-face('SprigSans', 200, italic, '../../fonts/FAIRE-SprigSans-ThinItalic');
@include sprig-font-face('SprigSans', 300, normal, '../../fonts/FAIRE-SprigSans-Light');
@include sprig-font-face('SprigSans', 300, italic, '../../fonts/FAIRE-SprigSans-LightItalic');
@include sprig-font-face('SprigSans', 400, normal, '../../fonts/FAIRE-SprigSans-Regular');
@include sprig-font-face('SprigSans', 400, italic, '../../fonts/FAIRE-SprigSans-RegularItalic');
@include sprig-font-face('SprigSans', 500, normal, '../../fonts/FAIRE-SprigSans-Medium');
@include sprig-font-face('SprigSans', 500, italic, '../../fonts/FAIRE-SprigSans-MediumItalic');
@include sprig-font-face('SprigSans', 600, normal, '../../fonts/FAIRE-SprigSans-Bold');
@include sprig-font-face('SprigSans', 600, italic, '../../fonts/FAIRE-SprigSans-BoldItalic');
@include sprig-font-face('SprigSans', 800, normal, '../../fonts/FAIRE-SprigSans-Black');
@include sprig-font-face('SprigSans', 800, italic, '../../fonts/FAIRE-SprigSans-BlackItalic');
@include sprig-font-face('SprigSans', 900, normal, '../../fonts/FAIRE-SprigSans-Super');
@include sprig-font-face('SprigSans', 900, italic, '../../fonts/FAIRE-SprigSans-SuperItalic');

@font-face {
    font-family: 'SprigSans Variable';
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/FAIRE-SprigSans-Variable.woff2') format('woff2');
}

@import 'fonts-mixins';

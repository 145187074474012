@mixin tile-on-hover {
    .tile-bottom {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            opacity: 0;
            pointer-events: none;
            transition: none;
        }

        .attribute {
            margin: 0 0 $space-s;

            &:has(.size-list.d-none) {
                margin: 0;
            }
        }
    }

    .plp-promos,
    .product-tile-badge {
        @include media-breakpoint-up(lg) {
            position: relative;
            max-height: rem(500);
            overflow: hidden;
            transition: none;
        }
    }

    .plp-promos {
        @include body-14-regular;

        color: $color-grey-dark;

        .promotion .info-icon-group {
            display: none;
        }
    }

    .price-variation {
        display: none;
    }

    .price-main {
        display: block;

        .on-sale-message {
            display: none;
        }
    }

    &:hover,
    &:has(*:focus-visible) {
        .tile-bottom {
            @include media-breakpoint-up(lg) {
                opacity: 1;
                pointer-events: all;
                transition: opacity $duration-default ease 0.3s;
            }
        }

        .plp-promos,
        .product-tile-badge {
            @include media-breakpoint-up(lg) {
                max-height: 0;
                transition: max-height $duration-default ease;
            }
        }

        .price-main {
            display: none;
        }

        .price-variation {
            display: block;

            .price-value.m-final-sale .percent-off-wrapper {
                display: none;
            }
        }
    }

    // prevent animation on focus moving inside the tile
    &:has(*:focus-visible) {
        .tile-bottom,
        .plp-promos,
        .product-tile-badge {
            @include media-breakpoint-up(lg) {
                transition: none !important;
            }
        }
    }
}

@mixin size-button {
    @include body-14-light;

    color: $dark-text;
    height: $swatch-size;
    min-width: $swatch-size;
    padding: 0 rem(6);
    position: relative;
    text-align: left;
    text-decoration: none;

    span {
        border-bottom: rem(1) solid transparent;
        padding-bottom: rem(2);
    }

    &:hover {
        span {
            color: $base-text-color;
            border-bottom-color: $border-color-med;
        }
    }

    &:focus-visible {
        outline: none;

        &:before {
            background: $body-bg;
            bottom: rem(-10);
            content: '';
            display: block;
            left: rem(-3);
            pointer-events: none;
            position: absolute;
            right: rem(-3);
            top: rem(-5);
            z-index: -1;
        }
    }

    &:focus-visible::before {
        outline: rem(2) solid $color-black;
    }

    &.not-available,
    &[disabled] {
        text-decoration: line-through;
        opacity: 0.5;

        &:hover span {
            color: $dark-text;
            border-bottom-color: transparent;
        }
    }

    &.not-available.selected,
    &.selected {
        span {
            border-bottom-color: $dark-text;
        }
    }

    &.btn {
        text-transform: none;
    }
}

@mixin extra-links {
    .tile-body-notify-me,
    .tile-body-add-to-cart,
    .early-access-sign-in {
        @include body-14-light;

        color: $dark-text;
        display: inline-block;
        padding: 0;
        text-align: left;
        transition: opacity $duration-default ease;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 1;
        }

        &[disabled],
        &[disabled]:hover {
            opacity: 0.5;
        }
    }

    .tile-body-notify-me {
        position: relative;

        i {
            &::before {
                height: rem(6);
                margin-top: rem(1);
                position: absolute;
                right: rem(-9);
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: rem(6);
            }
        }
    }

    .early-access-sign-in {
        padding-right: rem(12);
        position: relative;

        &.lp-icon-caret::after {
            height: rem(6);
            transform: rotate(-45deg);
            transition: none;
            width: rem(6);
        }
    }
}

@mixin product-image {
    aspect-ratio: 4 / 5;
    background: $product-tile-background-color;
    font-size: 0;
    line-height: 0;
}
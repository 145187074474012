@import './global/global';
@import './components/addProductToWishlistDialog';
@import './homepage/hero-asset';
@import './homepage/product-shelf';
@import './components/feature';
@import './components/ugc';
@import './components/doorway';

.page-top-navigation.header-transparent {
    .main-header {
        margin-bottom: var(--top-nav-height);
        transition: background $duration-fast linear;
    }

    @include media-breakpoint-up(lg) {
        &:not(:hover):not(.m-menu-opened):not(.search-open) {
            .main-header:not(.stuck) {
                background: transparent;

                .main-nav {
                    .header-nav-icons svg {
                        fill: var(--header-color);

                        &.svg-search-icon {
                            stroke: var(--header-color);
                        }
                    }

                    .header-nav-logo svg {
                        fill: var(--header-color);
                    }

                    .header-nav-icons .minicart-total .minicart-quantity {
                        background-color: $white;
                        color: $header-text-color;
                    }
                }

                .header .navbar-nav .nav-item {
                    .nav-link,
                    #endless-summer-sale,
                    #new-sale {
                        color: var(--header-color);

                        &::after,
                        &::before {
                            background: var(--header-color);
                        }
                    }

                    .sun-small {
                        color: var(--header-color);
                    }
                }
            }

            .carousel-stripe-banner-transparent {
                #carousel-stripe-banner {
                    background-color: transparent;
                    border-color: $header-background-color;

                    .carousel-item {
                        color: $stripe-text-color;

                        a {
                            color: $stripe-text-color;
                        }
                    }

                    .close-banner {
                        svg {
                            stroke: $stripe-text-color;
                            stroke-width: 2;
                        }
                    }

                    .stripe-banner-pause {
                        svg {
                            stroke: $stripe-text-color;
                        }
                    }

                    .stripe-banner-next {
                        svg {
                            stroke: $stripe-text-color;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &:not(.mobile-menu-open &):not(.search-open) {
            .main-header:not(.stuck) {
                background: transparent;

                .main-nav {
                    .header-nav-icons svg {
                        fill: var(--header-color);

                        &.svg-search-icon {
                            stroke: var(--header-color);
                        }
                    }

                    .header-nav-logo svg {
                        @include media-breakpoint-up(lg) {
                            fill: var(--header-color);
                        }
                    }

                    .header-nav-icons .minicart-total .minicart-quantity {
                        background-color: $white;
                        color: $header-text-color;
                    }
                }

                .header .navbar-nav .nav-item .nav-link {
                    @include media-breakpoint-up(lg) {
                        color: var(--header-color);
                    }
                }

                .navbar-nav .nav-item .nav-link {
                    &::after,
                    &::before {
                        @include media-breakpoint-up(lg) {
                            background: var(--header-color);
                        }
                    }
                }
            }

            .carousel-stripe-banner-transparent {
                #carousel-stripe-banner {
                    background-color: transparent;
                    border-color: $header-background-color;

                    .carousel-item {
                        color: $stripe-text-color;

                        a {
                            color: $stripe-text-color;
                        }
                    }

                    .close-banner {
                        svg {
                            stroke: $stripe-text-color;
                            stroke-width: 2;
                        }
                    }

                    .stripe-banner-pause {
                        svg {
                            stroke: $stripe-text-color;
                        }
                    }

                    .stripe-banner-next {
                        svg {
                            stroke: $stripe-text-color;
                        }
                    }
                }
            }
        }
    }

    .logo-animated {
        &:not(.m-scrolled) {
            &.m-menu-opened {
                .main-nav .header-nav-logo svg {
                    fill: var(--header-color);
                    opacity: 0.5;
                }
            }

            .header-nav-logo {
                @include media-breakpoint-up(lg) {
                    width: rem(400);
                }
            }

            .header-nav-icons .header-nav-logo {
                @include media-breakpoint-down(md) {
                    height: 0;
                    width: rem(460);
                }

                @include media-breakpoint-down(sm) {
                    width: calc(100% - $space-3xl);
                }
            }
        }

        &.m-scrolled {
            transition: background-color 0.5s;
            will-change: background-color;

            .header-nav-logo {
                transform: none !important;
            }

            .menu-group {
                margin-left: 0;
            }
        }

        .header-nav-icons .navbar-nav {
            flex-wrap: nowrap;
            align-self: flex-start;

            @include media-breakpoint-down(md) {
                margin-left: auto;
            }
        }

        .header-nav-logo {
            position: absolute;
            transition:
                transform 0.5s,
                width 0.5s;
            transform-origin: left top;
            will-change: transform, width;
        }

        .menu-group {
            @include media-breakpoint-up(lg) {
                margin-left: -22%;
                transition: margin-left 0.5s;
            }
        }

        &.logo {
            &-desktop {
                &-top {
                    .header-nav-logo {
                        @include media-breakpoint-up(lg) {
                            transform: translateX(-30px) translateY(12rem);
                        }
                    }

                    .search-menu {
                        .header-nav-logo {
                            position: static;
                            transform: none;
                            width: auto;
                        }
                    }
                }

                &-middle {
                    .header-nav-logo {
                        @include media-breakpoint-up(lg) {
                            transform: translateX(-30px) translateY(20vw);
                        }
                    }

                    .search-menu {
                        .header-nav-logo {
                            position: static;
                            transform: none;
                            width: auto;
                        }
                    }
                }

                &-bottom {
                    .header-nav-logo {
                        @include media-breakpoint-up(lg) {
                            transform: translateX(-30px) translateY(30vw);
                        }
                    }

                    .search-menu {
                        .header-nav-logo {
                            position: static;
                            transform: none;
                            width: auto;
                        }
                    }
                }
            }

            &-mobile {
                &-top {
                    .header-nav-icons .header-nav-logo {
                        @include media-breakpoint-down(md) {
                            transform: translateX(15vw) translateY(13vh);
                        }

                        @include media-breakpoint-down(sm) {
                            transform: translateX(0) translateY(7vh);
                        }
                    }

                    .in {
                        .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                position: static;
                                transform: none;
                                width: auto;
                            }
                        }
                    }
                }

                &-middle {
                    .header-nav-icons .header-nav-logo {
                        @include media-breakpoint-down(md) {
                            transform: translateX(15vw) translateY(31vh);
                        }

                        @include media-breakpoint-down(sm) {
                            transform: translateX(0) translateY(19vh);
                        }
                    }

                    .in {
                        .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                position: static;
                                transform: none;
                                width: auto;
                            }
                        }
                    }
                }

                &-bottom {
                    .header-nav-icons .header-nav-logo {
                        @include media-breakpoint-down(md) {
                            transform: translateX(15vw) translateY(62vh);
                        }

                        @include media-breakpoint-down(sm) {
                            transform: translateX(0) translateY(44vh);
                        }
                    }

                    .in {
                        .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                position: static;
                                transform: none;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }

        &:not(.m-scrolled) {
            .dropdown-container {
                .sub-menu-wrapper {
                    grid-column: 2 / 15;

                    @include media-breakpoint-down(md) {
                        grid-column: 1 / 16;
                    }
                }
            }
        }
    }

    .carousel-stripe-banner-transparent.show ~ {
        .logo-animated {
            &.logo {
                &-mobile {
                    &-top {
                        .header-nav-icons .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                transform: translateX(15vw) translateY(calc(13vh - rem(59)));
                            }

                            @include media-breakpoint-down(sm) {
                                transform: translateX(0) translateY(7vh);
                            }
                        }
                    }

                    &-middle {
                        .header-nav-icons .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                transform: translateX(15vw) translateY(calc(31vh - rem(59)));
                            }

                            @include media-breakpoint-down(sm) {
                                transform: translateX(0) translateY(19vh);
                            }
                        }
                    }

                    &-bottom {
                        .header-nav-icons .header-nav-logo {
                            @include media-breakpoint-down(md) {
                                transform: translateX(15vw) translateY(calc(62vh - rem(59)));
                            }

                            @include media-breakpoint-down(sm) {
                                transform: translateX(0) translateY(44vh);
                            }
                        }
                    }
                }
            }
        }
    }
}

.homepage-items {
    @media screen and (min-width: $page-grid-max-width) {
        width: 100%;
        overflow-x: hidden;
    }
}

.doorway {
    &-component {
        @include grid-layout;

        margin-block: $space-7xl;

        @include media-breakpoint-down(md) {
            margin-block: $space-6xl;
        }
    }

    &-block-1,
    &-block-2 {
        .content-main {
            flex-direction: column;
            margin: 0;
            padding-bottom: 0;
        }

        .video-component,
        .image {
            overflow: hidden;
            width: 100%;
        }

        .video-template {
            .doorway-component & {
                margin-top: 0 !important;
            }
        }

        img,
        video {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        .title,
        .video-sub-heading {
            @include heading-h2;

            margin-top: $space-xl;
        }

        a,
        .cta-link {
            @include body-14-light;

            display: inline-block;
            margin-top: $space-m;
            text-decoration-color: $link-decoration-color;
            transition:
                text-decoration-color $duration-default ease-in-out,
                color $duration-default ease-in-out;

            .title {
                @include body-14-light;

                color: inherit;
                font-family: inherit;
                margin-top: 0;
                text-align: left;
            }

            &:hover {
                color: $link-hover-color;
                text-decoration-color: $link-hover-color;
                text-decoration: underline;
            }
        }
    }

    &-block-1 {
        grid-column: 2/6;

        @include media-breakpoint-down(sm) {
            grid-column: 1/6;
        }

        .video-component,
        .image {
            aspect-ratio: 4/5;
        }

        .text-container {
            @include media-breakpoint-down(sm) {
                padding-left: $space-l;
            }
        }
    }

    &-block-2 {
        grid-column: 8/15;

        @include media-breakpoint-only(md) {
            grid-column: 7/15;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 2/7;
            margin-top: $space-4xl;
        }

        .text-container {
            @include media-breakpoint-up(lg) {
                width: 50%;
            }

            @include media-breakpoint-only(md) {
                width: 70%;
            }

            @include media-breakpoint-down(sm) {
                padding-right: $space-l;
            }
        }

        .video-component,
        .image {
            aspect-ratio: 5/4;
        }
    }
}

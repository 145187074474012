//
// Font Stacks
//
$crimson: 'Crimson Text Regular', serif;

$sprig-sans: 'SprigSans', 'Helvetica Neue', 'Helvetica', 'Arial', 'Nimbus Sans L', 'Liberation Sans', sans-serif;
$sprig-sans-variable: 'SprigSans Variable', 'Helvetica Neue', 'Helvetica', 'Arial', 'Nimbus Sans L', 'Liberation Sans', sans-serif;
$sprig-serif: 'Sprig', 'Georgia', 'DejaVu Serif', serif;
$text-security-disc: 'text-security-disc';
$font-primary: $sprig-sans;


@mixin font-serif-thin {
    font-family: $sprig-serif;
    font-weight: $font-weight-thin;
}

@mixin font-serif-extra-light {
    font-family: $sprig-serif;
    font-weight: $font-weight-extra-light;
}

@mixin font-serif-light {
    font-family: $sprig-serif;
    font-weight: $font-weight-light;
}

@mixin font-serif-regular {
    font-family: $sprig-serif;
    font-weight: $font-weight-regular;
}

@mixin font-serif-medium {
    font-family: $sprig-serif;
    font-weight: $font-weight-medium;
}

@mixin font-serif-semi-bold {
    font-family: $sprig-serif;
    font-weight: $font-weight-semi-bold;
}

@mixin font-serif-bold {
    font-family: $sprig-serif;
    font-weight: $font-weight-bold;
}

@mixin font-serif-extra-bold {
    font-family: $sprig-serif;
    font-weight: $font-weight-extra-bold;
}

@mixin font-serif-black {
    font-family: $sprig-serif;
    font-weight: $font-weight-black;
}

@mixin font-sans-thin {
    font-weight: $font-weight-thin;
}

@mixin font-sans-extra-light {
    font-weight: $font-weight-extra-light;
}

@mixin font-sans-light {
    font-weight: $font-weight-light;
}

@mixin font-sans-regular {
    font-weight: $font-weight-regular;
}

@mixin font-sans-medium {
    font-weight: $font-weight-medium;
}

@mixin font-sans-semi-bold {
    font-weight: $font-weight-semi-bold;
}

@mixin font-sans-bold {
    font-weight: $font-weight-bold;
}

@mixin font-sans-extra-bold {
    font-weight: $font-weight-extra-bold;
}

@mixin font-sans-black {
    font-weight: $font-weight-black;
}

// sans/serif size mixins
@mixin sans-family {
    font-family: $sprig-sans;

    @supports (font-variation-settings: normal) {
        font-family: $sprig-sans-variable;
    }
}

@mixin font-sans-10px {
    font-size: rem(10);
    line-height: 160%;
    letter-spacing: rem(-0.1);
}

@mixin font-sans-12px {
    font-size: rem(12);
    line-height: 160%;
    letter-spacing: rem(-0.12);
}

@mixin font-sans-14px {
    font-size: rem(14);
    line-height: 160%;
    letter-spacing: rem(-0.14);
}

@mixin font-sans-16px {
    font-size: rem(16);
    line-height: 160%;
    letter-spacing: rem(-0.16);
}

@mixin font-sans-18px {
    font-size: rem(18);
    line-height: 120%;
    letter-spacing: rem(-0.18);
}

@mixin font-serif-18px {
    font-size: rem(18);
    line-height: 120%;
    letter-spacing: rem(-0.18);
}

@mixin font-serif-20px {
    font-size: rem(20);
    line-height: 120%;
    letter-spacing: rem(-0.2);
}

@mixin font-serif-24px {
    font-size: rem(24);
    line-height: 120%;
    letter-spacing: rem(-0.24);
}

@mixin font-serif-28px {
    font-size: rem(28);
    line-height: 120%;
    letter-spacing: rem(-0.28); // TODO: this is duplicate letter-spacing: -0.01em; from body
}

@mixin font-serif-32px {
    font-size: rem(32);
    line-height: 120%;
    letter-spacing: rem(-0.96);
}

@mixin font-serif-34px {
    font-size: rem(34);
    line-height: 110%;
    letter-spacing: rem(-1.02);
}

@mixin font-serif-42px {
    font-size: rem(42);
    line-height: 110%;
    letter-spacing: rem(-1.26);
}

@mixin title-text-upper {
    font-size: rem(12);
    font-weight: $font-weight-medium;
    letter-spacing: rem(0.48);
    line-height: 125%;
    text-transform: uppercase;
}

/* Headings */

@mixin heading-h1 {
    @include font-serif-extra-light;
    @include font-serif-42px;

    @include media-breakpoint-down(md) {
        @include font-serif-34px;
    }
}

@mixin heading-h2 {
    @include font-serif-extra-light;
    @include font-serif-32px;

    @include media-breakpoint-down(md) {
        @include font-serif-28px;
    }
}

@mixin heading-h3 {
    @include font-serif-light;
    @include font-serif-24px;

    @include media-breakpoint-down(md) {
        @include font-serif-20px;
    }
}

@mixin heading-h4 {
    @include font-serif-light;
    @include font-serif-18px;
}

// Text font styles

@mixin body-10-regular {
    @include font-sans-regular;
    @include font-sans-10px;
}

@mixin body-12-light {
    @include font-sans-light;
    @include font-sans-12px;
}

@mixin body-12-regular {
    @include font-sans-regular;
    @include font-sans-12px;
}

@mixin body-12-medium {
    @include font-sans-medium;
    @include font-sans-12px;
}

@mixin body-14-light {
    @include font-sans-light;
    @include font-sans-14px;
}

@mixin body-14-regular {
    @include font-sans-regular;
    @include font-sans-14px;
}

@mixin body-14-medium {
    @include font-sans-medium;
    @include font-sans-14px;
}

@mixin body-14-semi-bold {
    @include font-sans-semi-bold;
    @include font-sans-14px;
}

@mixin body-16-light {
    @include font-sans-light;
    @include font-sans-16px;
}

@mixin body-16-regular {
    @include font-sans-regular;
    @include font-sans-16px;
}

@mixin body-16-medium {
    @include font-sans-medium;
    @include font-sans-16px;
}

@mixin body-18-light {
    @include font-sans-light;
    @include font-sans-18px;
}

@mixin label-info {
    @include font-sans-medium;
    @include font-sans-12px;

    letter-spacing: rem(0.36);
    text-transform: uppercase;
}

@mixin custom-list {
    list-style: none;
    padding-left: $space-xl;

    li {
        position: relative;

        &:before {
            background-color: $base-text-color;
            border-radius: 50%;
            content: '';
            display: block;
            height: rem(3);
            left: rem(-12);
            position: absolute;
            top: rem(12);
            width: rem(3);
        }
    }
}

:root {
    --font-sans: #{$sprig-sans-variable};
    --font-serif: #{$sprig-serif};
}

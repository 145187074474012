.product-shelf {
    margin: 0 auto;
    max-width: $site-max-width;

    .hero-shelf-caption {
        display: none;
    }

    .carousel-feature {
        padding-top: $space-3xl;
        position: relative;
    }
}

// Hacks to align Hero caption with Shelf component caption
.hero-asset + .product-shelf,
.product-shelf.hero-product-shelf {
    &:has(.hero-asset-caption) {
        .product-shelf-caption {
            display: none;
        }

        .hero-shelf-caption:has(.hero-asset-caption) {
            display: contents;
        }

        .hero-asset-caption {
            margin-bottom: 0;
        }

        .hero-asset-description {
            margin-top: 0;
        }
    }
}

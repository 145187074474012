$white: #fff;
$black: #000;
$lilly-pink: #e00083;
$lilly-navy: #19193d;
$monza: #e00024;
$emperor: #4f4f4f;
$boulder: #767676;
$silver: #ccc;
$mercury: #e1e1e1;
$wildsand: #f6f6f6;
$mulled-wine: #474764;
$alto: #cecece;
$dusty-gray: #979797;
$navy-blue: #006;
$rosseau-pink: #ffd1ff;
$anzac: #dca144;
$alabaster: #fafafa;
$hot-pink: #e6007a;
$lola: #e7dde7;
$moonraker: #e5cbf7;
$scorpion: #575757;
$lavender-blush: #ffedf0;
$selago: #faf7fe;
$light-pink: #fcf4f9;
$light-purple: #f0eeff;
$light-grayish-blue: #f4f6fc;
$gallery: #f0f0f0;
$ghostwhite: #f3f3f7;
$magenta-pink: #fcf4f9;
$rustic: #bbb1b1;
$venetian-red: #d0021b;
$whiteish-grey: #eaeaea;
$placebo-purple: #f7f1fd;
$magnolia: #fcfaff;
$pale-lavender: #e5daff;
$whisper: #e7e7e7;
$concrete: #f3f3f3;
$snow: #fff4fa;
$titan-white: #ebe9ff;
$crimson-red: #e30083;
$gainsboro: #d8d8d8;
$cadmium-red: #e00023;
$forest-green: #228b22;
$lilly-logo-pink: #fe1ba1;
// redesign
$color-black: #000;
$color-grey-dark: #535556; //33%
$color-grey-medium: #d8d8d8; //85%
$color-gray-light80: #bebebe; // 80%
$color-grey-light: #eee; //93%
$color-grey-lightest: #f9f9f9; //98%
$color-white: #fff;
$color-pink: #ef5da0;
$color-pink93: #fcdfec;
$color-cherry: #c00f44;
$color-green: #0fc000;
$color-functional-error: $color-cherry;
$color-ruby-red: #e62737;
$color-yellow: #ffc40c;
$color-pink-sherbet: #ef5da0;
$color-pink-blush: #de5695;
$color-light-pink: $color-pink93;
$color-light-cyan: #c7eeff;
$color-aero-blue: #c5f9ec;
$color-night-blue: #004563;

$body-bg: $color-white;

// general variables
$base-text-color: $color-grey-dark;
$dark-text: $color-black;
$light-text: $color-white;
$error-border-color: $color-functional-error;
$error-text-color: $color-functional-error;
$select-icon-border-color: $emperor;
$link-seperator-border-color: $dusty-gray;
$light-border: $mercury;
$grey-border: $mercury;
$silver-border: $silver;
$active-border: $emperor;
$border-color-med: $color-grey-medium;
$icon-bg-color: $emperor;
$tertiary-link-underline-color: $color-grey-dark;
$page-title-background-color: $white;
$page-title-color: $emperor;
$selectbox-border: $silver;
$dropdown-background-color: $white;
$dropdown-hover-background-color: $color-grey-light;
$icon-background-color: $emperor;
$base-border-color: $emperor;
$checkbox-border-color: $emperor;
$input-button-border-color: $emperor;
$unselected-input-border-color: $silver;
$down-arrow-border-color: $lilly-pink;
$active-background-color: $wildsand;
$caret-scroll-bg-color: rgba($white, 0.85);
$outline-color: $mulled-wine;
$back-to-top-bg-color: $white;
$back-to-top-bg-hover-color: rgba($color-black, 0.5);
$back-to-top-border-color: $color-grey-light;
$error-page-background: $selago;
$border-bottom-nav-tab: $rustic;
$selected-nav-tab: $lilly-pink;
$unselected-nav-tab: $gallery;
$save-for-later-sale-price: $venetian-red;
$page-navigation-text-link-color: $lilly-pink;
$headline-color: $color-black;
$divider-color: $color-grey-light;

//Carousel
$carousel-navigation-button-shadow: $silver;
$carousel-arrow-hover-bg: $color-grey-light;
$carousel-arrow-color: $color-black;
$carousel-arrow-disable-color: $color-grey-medium;
$carousel-arrow-bg-on-light: rgba($color-white, 0.5);
$carousel-arrow-bg-on-dark: rgba($color-black, 0.5);
$carousel-arrow-hover-bg-on-dark: $color-black;

// Links
$link-color: $base-text-color; // main text color, should be passed explicitly due to Bootstrap logic
$link-decoration: underline;
$link-decoration-color: $color-grey-medium;
$link-decoration-color-dark: $color-grey-dark;
$link-hover-color: $dark-text;
$link-decoration-color-on-light: rgba($color-black, 0.4);
$link-decoration-color-on-dark: rgba($color-white, 0.4);

// Modal
$modal-nav-btn-color-hover: $color-black;
$modal-nav-btn-bg-hover: rgba(0, 0, 0, 7%); // same as $color-grey-light;
$modal-footer-divider: $color-grey-light;

// search
$search-border-color: $border-color-med;
$search-input-text-color: $color-black;
$search-text-color: $color-grey-medium;
$no-search-text-color: $emperor;
$search-menu-background-color: $white;
$sort-btn-text-color: $color-black;
$option-check-color: $color-black;
$sort-image-bg: $wildsand;

// filters
$filters-text-color: $black;
$filters-color-border: rgba($black, 0.1);
$filter-text-count-color: $color-grey-dark;
$selected-filter-button-background-color: $black;
$selected-filter-button-border-color: $black;
$filter-swatch-border-color: $silver;
$filter-text-input-placeholder-color: $boulder;
$price-filter-button-color: $white;

// monogram
$monogram-selection-blue: $navy-blue;
$monogram-selection-white: $wildsand;
$monogram-selection-pink: $rosseau-pink;
$monogram-selection-gold: $anzac;
$monogram-selection-border-color: $color-grey-medium;
$monogram-letter: $color-black;
$monogram-letter-inactive: $color-grey-medium;
$monogram-arrow-color: $emperor;
$monogram-confirm-text: $color-grey-dark;
$monogram-confirm-text-emph: $color-black;
$monogram-cta-border-color: $gainsboro;

// sizechart colors
$scrollbar-pink: $lilly-pink;
$sizechart-menu-bg: $white;
$table-row-grey: $wildsand;
$table-row-white: $white;
$active-border-bottom-navy: $lilly-navy;
$accordion-border-silver: $silver;

// gallery colors
$gallery-scrollbar-bg: $color-grey-light;
$gallery-scrollbar-drag-bg: $color-black;
$gallery-pagination-bg: $color-white;
$gallery-button-border: $color-grey-light;
$gallery-button-bg: $color-white;
$gallery-button-hover-bg: $color-grey-light;
$gallery-bg-color: $alabaster;

// custom buttons colors
$primary-bg-color: $black;
$primary-color: $white;
$primary-hover-bg-color: lighten($primary-bg-color, 20%);
$primary-active-bg-color: $color-grey-dark;
$primary-disabled-color: $white;
$primary-disabled-bg-color: $color-grey-medium;
$primary-focus-color: $black;

$secondary-bg-color: $white;
$secondary-bd-color: $color-grey-medium;
$secondary-color: $black;
$secondary-hover-bd-color: $color-grey-dark;
$secondary-active-bd-color: $black;
$secondary-active-bg-color: $white;
$secondary-disabled-color: $color-grey-medium;
$secondary-disabled-bg-color: $color-grey-light;
$secondary-disabled-border-color: $color-grey-light;
$secondary-border-on-light: rgba(0, 0, 0, 0.4);
$secondary-border-on-dark: rgba(255, 255, 255, 0.4);

$light-modal-background-color: rgba(255, 255, 255, 0.8);
$close-button-color: $black;

// Product colors
$price-color: $color-black;
$sale-price-color: $dark-text;
$percent-off-color: $emperor;
$selected-swatch-border-color: $lilly-navy;
$sale-swatch-indicator-color: $lilly-pink;
$product-tile-background-color: $color-grey-lightest;
$product-unavailable-message-color: $monza;
$swatch-selection-border-color: rgba($color-black, 0.3);
$swatch-outline-color: rgba($color-black, 0.3);
$sale-message-color: $monza;
$add-to-cart-message-background: $white;
$add-to-cart-message-color: $emperor;
$swatch-neon-color: $lilly-pink;
$approaching-discount-color: $lilly-pink;
$plp-badge-color: $base-text-color;
$plp-link-color: $black;
$plp-price-color: $black;
$promo-callout-message-block-text: $color-black;
$afterpay-link-bg: $white;
$product-image-unavailable-bg: $color-grey-lightest;

// Cart & Checkout
$seperator-color: $dusty-gray;
$link-color: $base-text-color;
$card-border-color: $silver;
$card-header-color: $boulder;
$promo-code-border-color: $silver;
$product-total-price-color: $emperor;
$gwp-title-border-color: $silver;
$cart-error: $error-text-color;
$out-of-stock-color: $error-text-color;
$coupon-code-bg: $color-grey-light;
$product-name-color: $link-color;
$checkout-login-title-border: $lilly-navy;
$size-label-color: $boulder;
$bonus-product-border-color: $silver;
$checkout-button-background-color: $white;
$help-text-color: $emperor;
$order-total-border-color: $silver;
$checkout-total-text-color: $emperor;
$check-mark-color: $lilly-pink;
$receipt-header-border-color: $lilly-pink;
$order-detail-background-color: $white;
$checkout-summary-title-border-color: $silver;
$apple-pay-button-color: $white;
$checkout-title-color: $lilly-navy;
$checkout-login-card-heading-border-color: $lilly-pink;
$checkout-line-item-attribute-color: $boulder;
$checkout-line-item-text-color: $emperor;
$cart-gwp-message-color: $lilly-pink;
$apply-promo-color: $white;
$billing-address-border-color: $silver;
$availability-error-message-color: $monza;
$checkout-error-border-color: $monza;
$checkout-error-heading-color: $lilly-navy;
$checkout-error-text-color: $emperor;
$checkout-error-background: $lavender-blush;
$gwp-updated-border-color: $monza;
$gwp-updated-background-color: $wildsand;
$order-detail-return-banner-color: $wildsand;
$e-gift-card-noneturnable-bg: $wildsand;
$cart-error-background-color: $lavender-blush;
$afterpay-circle-fill: $color-black;
$cart-gwp-select-btn-border: rgba($color-black, 0.3);
$cart-gwp-select-item-border: $color-grey-light;
$cart-gwp-select-item-border-selected: $black;

// Shipment
$info-icon-border: $emperor;
$active-info-icon-bg: $boulder;
$inactive-payment-step: $gainsboro;

//payment
$payment-input-border-color: $gainsboro;
$input-label-color: $color-grey-dark;
$payment-dark-label-color: $color-black;

// Headings
$heading-pink: $lilly-pink;

// Pick up in store
$store-results-heading: $black;
$stock-available: $lilly-pink;
$geolocation-error-color: $monza;
$store-border-color: $color-grey-light;
$store-available: $color-green;
$store-unavailable: $color-cherry;
$store-limited: $color-yellow;
$store-toggle-color: $color-grey-dark;
$store-selected-size-border-color: $black;
$preferred-store-border-color: $color-gray-light80;

// form elements
$form-control-border-color: $color-grey-medium;
$form-control-text-color: $color-black;
$form-control-label-color: $color-grey-dark;
$form-control-focused-border-color: $color-black;
$form-control-birthday-border-color: $color-grey-light;
$custom-control-text-color: $white;
$custom-control-border-color: $color-grey-medium;
$custom-control-background-color: $white;
$checkbox-checked-border-color: $color-black;
$checkbox-checked-background-color: $color-black;
$checkbox-focus-outline-color: $color-black; // could be unified across all inputs
$checkbox-disabled-border-color: $color-grey-light;
$checkbox-disabled-background-color: $color-grey-light;
$radio-background-color: $color-white;
$radio-border-color: $color-grey-medium;
$radio-dot-color: $color-black;
$radio-active-border-color: $color-black;
$radio-focus-outline-color: $color-black; // could be unified across all inputs
$radio-disabled-background-color: $color-grey-light;
$password-requirement-default-color: $color-grey-medium;
$password-requirement-valid-color: $color-green;
$loyalty-signup-box-background-color: $color-grey-lightest;
$loyalty-signup-box-selected-border: $black;

//table element
$table-border: $color-grey-medium;
$table-color: $color-black;
$table-head-bg: $color-grey-lightest;

// quick view
$quick-view-button-background-color: $white;
$quick-view-button-border-color: $boulder;
$quick-view-text-color: $lilly-navy;
$quick-view-error-color: $monza;
$quick-view-size-btn-hover-background-color: $wildsand;
$quick-view-size-btn-selected-border-color: $emperor;

// footer
$footer-background-color: $white;
$footer-border-color: $lilly-pink;
$footer-title-color: $black;
$footer-link-color: $black;
$footer-link-border: $color-white;
$footer-link-hover-border: $color-grey-medium;
$footer-accordion-border-color: $divider-color;
$checkout-footer-bg-color: $color-grey-light;
$checkout-footer-divider-color: $white;

// Gift card
$gift-card-value-color: $color-black;
$gift-card-error-color: $error-text-color;
$gift-card-image-background-color: $alabaster;
$gift-card-denomination-hover-color: $base-text-color;
$gift-card-denomination-selected-color: $color-black;
$gift-card-denomination-selected-border-color: $color-black;
$gift-card-currency-symbol: $emperor;
$gift-card-custom-amount-placeholder-color: $boulder;
$cart-gift-card-value-color: $emperor;
$gift-card-banner-background-color: $gallery;

//homepage
$home-column-heading-color: $hot-pink;
$home-column-btn-border-color: $lilly-navy;
$home-column-btn-link-color: $lilly-navy;
$home-column-content-color: $emperor;
$home-banner-title-color: $emperor;
$home-banner-cta-color: $emperor;
$home-shop-btn-border-color: $white;
$home-shop-text-color: $white;
$home-family-text-color: $lilly-navy;
$home-family-btn-border-color: $lilly-navy;
$home-image-bg-color: $color-grey-light;

//account
$need-help-border-color: $silver;
$account-card-hint-text: $boulder;
$account-payment-border-color: $silver;
$account-cards-selector-border-color: $silver;
$account-cards-selector-arrow-color: $silver;
$account-item-border-color: $color-grey-light;

// Header - Mega Menu
$header-text-color: $dark-text;
$header-border-color: $divider-color;
$header-item-border-color: $color-black;
$header-background-color: $white;
$header-banner-background-color: $lilly-navy;
$header-focus-background-color: $wildsand;
$header-outline-color: $silver;
$header-highlighted-color: $lilly-pink;
$header-logo-color: $lilly-logo-pink;
$stripe-icon-color: $white;
$stripe-text-color: $white;
$mini-cart-quantity-bg: $color-black;

//Category Landing Page
$clp-text-color: $emperor;
$clp-tab-border-color: $lilly-pink;

// Store locator
$store-locator-phone: $emperor;
$store-marker-border-color: $black;
$store-name-underline-color: $black;
$store-locator-border-color: $color-grey-light;
$store-locator-dot-color: $lilly-pink;
$store-locator-gm-control-bg: rgba($color-black, 0.5);

//Wishlist
$wishlist-product-attr-label-color: $boulder;
$wishlist-items-separator-color: $lilly-pink;
$wishlist-item-availability-message-color: $monza;
$wishlist-item-availability-message-bg-color: $wildsand;
$wishlist-search-error-color: $monza;
$wishlist-unavailable-msg: $color-cherry;

//Login
$login-banner-bg: $mercury;
$login-banner-fg: $lilly-navy;

//My Account - Address Book
$address-action-link-separator: $boulder;
$address-card-border: $silver;
$wishlist-results-table-even-background-color: $wildsand;

//contact us
$contact-us-border-color: $silver;
$customer-service-active-link-arrow-color: $emperor;
$customer-service-element-background: $color-grey-lightest;

// video-component
$video-component-title-color: $emperor;

// Forgot Password
$forgot-password-header-text-color: $emperor;

// spinner
$spinner-background-color: $silver;

// order history
$order-history-dropdown-item-background-color: $wildsand;
$order-history-dropdown-item-color: $base-text-color;
$order-history-placeholder-bg-color: $color-grey-lightest;
$order-history-pagination-placeholder: $color-grey-medium;

//order details
$order-details-background-color: $white;
$order-details-adjusted-total-color: $lilly-pink;
$order-details-section-border-color: $color-grey-light;

// spinner
$spinner-background-color: $silver;

//skeleton loader
$skeleton-loader-first-color: $color-grey-light;
$skeleton-loader-second-color: rgba($color-grey-light, 0.35);

// shop by print
$sbp-quickview-bg-color: $white;
$sbp-quickview-title-color: $base-text-color;
$sbp-drawer-bg-color: $white;
$sbp-mini-swatch-text-color: $lilly-navy;
$sbp-header-navy-color: $lilly-navy;
$sbp-header-white-color: $white;
$sbp-header-grey-color: $emperor;
$sbp-overlay-color: rgba($color-black, 0.5);
$spb-drawer-color: $base-text-color;
$spb-drawer-hover-color: $dark-text;

// breadcrumb
$breadcrumb-text-color: $base-text-color;
$breadcrumb-border-color: $gainsboro;

//ratings&reviews
$ratings-filter-border-color: $silver;
$ratings-stars-color: $lilly-navy;
$slider-bar-background-color: $silver;

// autocomplete
$suggestion-bg-color: $dropdown-background-color;
$suggestion-selected-bg-color: $active-background-color;

//Pickup Store delivery message PDP
$instore-pickup-message-color: $boulder;

// Switch component
$switch-border-color: $silver;
$switch-bg-color: $white;
$switch-on-bg-color: $lilly-pink;
$switch-off-bg-color: $silver;

// Alerts
$alert-info-background-color: $light-pink;
$alert-info-border-color: $lavender-blush;
$alert-info-text-color: $emperor;

// Loyalty
$loyalty-background-color: $magenta-pink;
$loyalty-swatch-selected-color: $black;
$loyalty-swatch-border-color: $color-grey-light;
$loyalty-swatch-selected-border-color: $color-grey-dark;
$loyalty-faq-border-color: $lilly-navy;
$loyalty-benefit-title-color: $color-black;
$loyalty-faq-content-color: $color-grey-dark;
$loyalty-faq-card-border-color: $silver;
$loyalty-faq-card-bg-color: $white;

//bazaarvoice
$bv-text: $color-black;
$bv-text-dimmed: $color-grey-dark;
$bv-border: $color-black;
$bv-border-light: $color-grey-light;
$bv-border-medium: $color-grey-medium;
$bv-border-dark: $color-grey-dark;
$bv-bg-color: $color-white;
$bv-bg-color-light: $color-grey-light;

$loyalty-message-background-color: $color-grey-light;
$loyalty-message-border-color: $color-grey-lightest;
$loyalty-heading-color: $color-grey-dark;
$loyalty-referral-background-color: $light-grayish-blue;
$loyalty-referral-text-color: $color-grey-dark;
$loyalty-referral-color: $emperor;
$loyalty-dashboard-rewards-bg-color: $placebo-purple;
$loyalty-dashboard-rewards-text-color: $emperor;
$loyalty-dashboard-points-bg-color: rgba($color-pink, 0.1);
$loyalty-dashboard-pointer-empty-color: rgba($color-pink, 0.2);
$loyalty-dashboard-pointer-shadow: $whisper;
$loyalty-dashboard-error-background: $wildsand;
$loyalty-activity-dropdown-arrow-color: $color-black;
$loyalty-activity-text-color: $color-black;
$loyalty-activity-dropdown-selected-bg-color: $wildsand;
$loyalty-activity-divider-line: $emperor;
$loyalty-activity-primary-background: $wildsand;
$loyalty-activity-secondary-background: $titan-white;
$loyalty-activity-tertiary-background: $snow;
$loyalty-order-details-error: $cadmium-red;
$loyalty-order-details-points-text: $crimson-red;
$loyalty-points-earned-this-year-color: $lilly-navy;
$loyalty-points-earned-this-year-text-color: $emperor;
$loyalty-account-view-dashboard-color: $emperor;
$loyalty-benefit-lock-color: rgba(25, 25, 61, 0.5);
$loyalty-dashboard-header-border-color: $lilly-navy;
$loyalty-border-color: $lilly-navy;
$loyalty-benefits-scrollbar-color: $lilly-navy;
$loyalty-benefits-border-color: $color-grey-light;
$loyalty-birthdaybanner-color: $white;
$loyalty-birthdaybanner-link-color: $color-grey-dark;
$loyalty-birthdaybanner-bg-color: $black;
$loyalty-badge-background-color: $color-pink93;
$loyalty-badge-text-color: $color-grey-dark;
$loyalty-new-tag-color: $lilly-navy;
$loyalty-guest-new-tag-color: $lilly-navy;
$loyalty-vouchers-scrollbar-color: $dusty-gray;
$loyalty-vertical-scrollbar-color: $lilly-navy;
$loyalty-enroll-background-color: $white;
$loyalty-day-counter-background-color: $color-pink93;
$loyalty-dashboard-offer-reveal-btn-border: rgba($color-black, 0.3);
$loyalty-pointer-loss-bg-color: $color-pink93;

// Loyalty Vouchers
$loyalty-voucher-card-bg: $color-grey-lightest;
$loyalty-voucher-card-bg-applied: $color-grey-light;
$loyalty-voucher-overflow-divider: $color-grey-medium;

//Stylitics
$stylitics-jumplink-bg-color: $white;
$stylitics-jumplink-border-color: $boulder;
$stylitics-jumplink-text-color: $emperor;
$stylitics-text-hover-color: $boulder;
$stylitics-badge-border-color: $boulder;
$stylitics-badge-background-color: $wildsand;
$stylitics-default-bundle-border-color: $grey3;
$stylitics-active-bundle-border-color: $boulder;
$stylitics-plp-border: $silver;
$stylitics-image-bg: $color-grey-lightest;
$stylitics-bundle-details-bg: $white;
$stylitics-backdrop: rgba($black, 0.15);
$stylitics-arrow-hover-bg: $color-grey-light;

//Bolt
$bolt-card-bg-color: $wildsand;
$bolt-card-content-text: $emperor;
$bolt-button-border-color: $lilly-navy;
$bolt-check-mark-bg-color: $color-black;
$bolt-check-mark-light-color: $white;

//Gift card on cart
$gift-card-in-cart-background-color: $color-grey-lightest;
$gift-card-in-cart-border-color: $color-grey-light;
$quick-gift-cart-btn-text-color: $emperor;
$gift-card-selected-amount-background: $white;
$quick-gift-card-add-to-cart-button-color: $lilly-navy;
$gift-card-amount-hover-border: $gainsboro;

//Notify me box
$notifyme-box-bg-color: $wildsand;
$notifyme-box-text-color: $emperor;
$notifyme-box-text-color-accent: $lilly-navy;
$notifyme-box-border-color: $silver;

//Blog
$blog-hub-post-color: $boulder;
$blog-hub-post-title-color: $emperor;
$blog-hub-post-image-bg-color: $mercury;
$blog-hub-banner-color: $white;
$blog-entry-date-color: $boulder;
$blog-entry-title-color: $emperor;
$blog-pagination-select-border-color: $silver-border;
$blog-scroll-bg-color: $wildsand;
$blog-scroll-fg-color: $boulder;

// Popover
$popover-border-color: $color-black;
$popover-color: $color-grey-dark;

//Chat with stylist
$stylist-chat-msg-color: $color-grey-light;
$stylist-chat-border-color: $color-grey-light;
$stylist-chat-close-btn-color: $black;
$stylist-chat-background-color: $white;
$stylist-chat-settings-handle-color: $black;
$stylist-chat-user-msg-background-color: $color-pink93;
$stylist-chat-text-color: $black;
$stylist-chat-input-color: $gainsboro;
$start-chat-btn-color: $white;
$stylist-chat-required-field-color: $color-ruby-red;
$stylist-chat-sms-continue-btn-color: $black;
$stylist-chat-box-shadow-color: $black;
$stylist-chat-country-list-border-color: $color-grey-light;
$stylist-chat-country-list-background-color: $color-white;
$stylist-chat-notification-color: $color-pink-sherbet;
$stylist-chat-notification-hover-color: $color-pink-blush;
$stylist-chat-header-timestamp-color: $color-grey-dark;
$stylist-chat-inactive-button: $gainsboro;
$stylist-chat-input-active-color: $black;
$stylist-chat-text-inactive-color: $color-grey-dark;
$stylist-chat-upload-close-btn: $color-grey-light;
$stylist-chat-error-msg: $color-cherry;
$stylist-chat-overlay-bg-color: rgba(0, 0, 0, 0.5);
$stylist-chat-link-color: $color-black;
$stylist-chat-btn-bg-color: $color-grey-light;

// Slideout Sheet/Drawer
$sheet-primary-bg: $white;
$sheet-close-bg: rgba(255, 255, 255, 0.5);
$sheet-close-bg-hover: $color-grey-light; // same as $color-grey-light;

// Flex Tabs
$tab-fg-color: $black;
$tab-hover-color: $color-grey-dark;

// Line Item Card
$line-item-title-color: $headline-color;

// Scrollbar
$scrollbar-color: $black;
$scrollbar-color-track: $color-grey-light;
$scrollbar-color-on-light: rgba($color-black, 0.1);
$scrollbar-color-on-dark: rgba($color-white, 0.3);
$scrollbar-color-track-on-dark: $color-white;

//Shoppable media
$shoppable-border-color: $color-grey-medium;
$shoppable-scroll-color: $color-black;
$shoppable-scrollbar-color: $color-grey-light;
$shoppable-playbutton-background: $color-black;

//PLP Highlight Module
$filter-highlight-header-title: $black;

//Stylitics
$stylitics-card-bg: $alabaster;
$stylitics-cta-border-color: $gainsboro;
$stylitics-arrow-color: $gainsboro;
$stylitics-cta-arrow-color: $color-black;
$stylitics-modal-bg: $white;

// Breadcrumb
$breadcrumb-separator: $color-grey-medium;

// PDP Lightbox colors:
$lightbox-thumb-active-border: $black;
$lightbox-description-color: $black;

// PLP/PDP wishlist alert
$wishlist-alert-item-color: $black;
$wishlist-alert-item-hover-bg: $color-grey-light;
$wishlist-alert-item-hover-color: $base-text-color;
// Images
$image-background: $color-grey-lightest;

$toggle-off-bg: $silver;
$toggle-on-bg: $color-black;
$toggle-knob: $color-white;

:root {
    // base colors
    --grey-dark: #{$color-grey-dark};
    --grey-med: #{$color-grey-medium};
    --grey-light: #{$color-grey-light};
    --grey-lightest: #{$color-grey-lightest};

    // aliases
    --base-text-color: #{$base-text-color};
    --dark-text: #{$dark-text};
    --error-text: #{$error-text-color};
}

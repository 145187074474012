/* Site specific global styles*/
@import '../functions/grid-span';
.swiper {
    .image-container {
        overflow: unset;
    }
    .swiper-lazy-image {
        display: none !important;
    }
    &.m-no-slider {
        background: $gallery-bg-color;
    }

    &-button {
        background-color: $gallery-button-bg;
        border-radius: 50%;
        border: rem(1) solid $gallery-button-border;
        height: $global-icon-link-size;
        line-height: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition:
            opacity $duration-default ease-out,
            background-color $duration-default ease-out;
        width: $global-icon-link-size;
        z-index: $zindex-lowest;

        .image-container &:hover,
        .primary-images &:hover {
            background-color: $gallery-button-hover-bg;
        }

        &.m-prev {
            left: rem(16);
        }

        &.m-next {
            right: rem(16);
        }

        &.swiper-button-lock {
            display: none !important;
        }
    }

    &-pagination {
        background-color: $gallery-pagination-bg;
        font-size: rem(14);
        padding: $space-xs;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            min-width: rem(60);
            text-align: right;
        }

        @include media-breakpoint-up(lg) {
            bottom: 0;
            padding: rem(12) rem(14);
            position: absolute;
            right: 0;
            z-index: $zindex-lowest;
        }

        &.swiper-pagination-lock {
            display: none;
        }
    }

    &-scrollbar {
        background-color: $gallery-scrollbar-bg;

        @include media-breakpoint-up(lg) {
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: rem(5);
            z-index: $zindex-lowest;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            height: rem(2);
        }

        &.swiper-scrollbar-lock {
            display: none;
        }

        .swiper-scrollbar-drag {
            background-color: $gallery-scrollbar-drag-bg;
            height: rem(5);
            width: rem(5);

            @include media-breakpoint-down(md) {
                height: rem(2);
                background-color: $gallery-scrollbar-drag-bg;
            }
        }

        .on-color-light & {
            background-color: $scrollbar-color-on-light;
        }

        .on-color-dark & {
            background-color: $scrollbar-color-on-dark;
            .swiper-scrollbar-drag {
                background-color: $scrollbar-color-track-on-dark;
            }
        }
    }

    &-bottom {
        background: $white; // body bg

        @include media-breakpoint-down(md) {
            padding: $space-2xl grid-span(1) 0;
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-down(sm) {
            padding: $space-xl $space-l 0;
        }
    }

    .primary-images &-slide {
        @include media-breakpoint-up(lg) {
            border: rem(0.5) solid transparent;
        }
    }
}

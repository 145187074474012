.ugc {
    @include grid-layout;

    margin-top: $space-7xl;

    @include media-breakpoint-down(md) {
        margin-top: $space-6xl;
    }

    &-inner {
        grid-column: 1/17;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }

        img {
            width: 100%;
        }
    }

    &-header {
        margin-bottom: $space-2xl;
        grid-column: 2/15;

        @include media-breakpoint-up(lg) {
            grid-column: 4/16;
            display: flex;
            margin-bottom: $space-3xl;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
            padding-inline: $space-l;
        }
    }

    &-title {
        @include heading-h3;

        color: $dark-text;
        margin-bottom: $space-s;

        @include media-breakpoint-up(lg) {
            flex: 0 0 25%;
        }
    }

    &-description {
        @include media-breakpoint-up(lg) {
            flex: 0 0 50%;
        }
    }

    &-copy {
        @include body-14-light;

        + .ugc-link {
            margin-top: $space-xs;
        }
    }

    &-nav {
        display: flex;
        flex: 0 0 25%;
        gap: $space-s;
        justify-content: center;

        @include media-breakpoint-down(md) {
            display: none;
        }

        button {
            width: $global-icon-link-size;
            height: $global-icon-link-size;
        }

        &:has(.m-prev.swiper-button-disabled):has(.m-next.swiper-button-disabled) {
            display: none;
        }
    }

    .swiper-outer {
        grid-column: 1/16;
        padding-top: 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }
    }

    .swiper-bottom {
        @include grid-inner-standard;

        padding-top: $space-3xl;

        @include media-breakpoint-only(md) {
            padding-inline: 0;
        }
    }

    .swiper-slide {
        aspect-ratio: 4 / 5;
        overflow: hidden;

        .rendering-template {
            height: 100%;
            width: 100%;
        }

        .image {
            width: 100%;
        }

        .img-fluid,
        .video {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .video {
            grid-area: 1 / -1;
        }
    }

    .video-component {
        display: contents;

        .video-controls {
            bottom: 0;
            grid-area: 1 / -1;
            margin: auto 0 0 auto;
            padding: $space-s;
            pointer-events: none;
            position: relative;
            right: 0;
            width: max-content;
            z-index: $zindex-controls;
        }

        .mute-video,
        .play-video {
            visibility: visible !important;
            opacity: 1 !important;
            pointer-events: all;
        }
    }

    .video-template {
        height: 100%;
        margin: 0;
        position: relative;
        display: grid;
    }

    .video-headings {
        display: none;
    }

    .content-tile {
        padding: 0;
        height: 100%;
    }

    .text-container {
        display: none;
    }
}

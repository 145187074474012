@mixin grid-layout {
    display: grid;
    gap: 0 $page-grid-gutter;
    grid-template-columns: repeat($page-grid-columns, 1fr);

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat($page-grid-columns-sm, 1fr);
    }
}

@mixin grid-inner-standard {
    grid-column: 2/15;

    @include media-breakpoint-down(sm) {
        grid-column: 1/7;
        padding-inline: $space-l;
    }
}

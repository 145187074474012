.feature {
    &-component {
        @include grid-layout;
    }

    &-media {
        grid-column: 1/16;
        overflow: hidden;
        width: 100%;

        &.media-square {
            aspect-ratio: 1/1;

            @include media-breakpoint-up(lg) {
                grid-column: 4/16;
            }
        }

        &.media-rectangle {
            aspect-ratio: 16/9;

            @include media-breakpoint-down(md) {
                aspect-ratio: 2/3;
            }
        }

        @include media-breakpoint-only(md) {
            grid-column: 1/16;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
        }

        .video-component,
        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        & + .feature-text-container {
            margin-top: $space-4xl;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }
    }

    &-text-container {
        grid-column: 4/10;
        margin-top: $space-6xl;
        margin-bottom: $space-4xl;

        @include media-breakpoint-down(md) {
            grid-column: 2/12;
            margin-top: $space-5xl;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1/7;
            padding-inline: $space-l;
        }

        .on-color-dark & {
            color: $light-text;
        }
    }

    &-title {
        @include heading-h1;

        color: $dark-text;

        .on-color-dark & {
            color: $light-text;
        }
    }

    &-links {
        margin-top: $space-l;

        .cta-link {
            .on-color-dark & {
                color: $light-text;
                text-decoration-color: $link-decoration-color-on-dark;

                &:hover {
                    text-decoration-color: $color-white;
                }
            }

            .on-color-light & {
                text-decoration-color: $link-decoration-color-on-light;
                color: $dark-text;

                &:hover {
                    text-decoration-color: $color-black;
                }
            }

            + .cta-link {
                margin-left: $space-m;
            }
        }
    }
}

.product-shelf:has(.feature-component) {
    background-color: var(--backgroundModule);
    margin-top: $space-4xl;
    padding-block: 0 $space-5xl;
    position: relative;

    &.full-bleed:before {
        background-color: var(--backgroundModule);
        content: '';
        position: absolute;
        z-index: -1;

        @media screen and (min-width: $page-grid-max-width) {
            height: 100%;
            left: calc((100vw - $page-grid-max-width) / -2);
            width: 100vw;
        }
    }

    .swiper-outer {
        @include media-breakpoint-down(md) {
            padding-top: $space-s;
        }
    }

    &.on-color-dark {
        .product {
            .link,
            .price,
            .sales,
            .size-btn,
            .swatches-more-colors,
            .tile-body-add-to-cart {
                color: $light-text;
            }

            .size-btn.selected span {
                color: $light-text;
                border-color: $link-decoration-color-on-dark;
            }

            .product-tile-swatch {
                &.selected .swatch-circle{
                    box-shadow:
                        0 0 0 rem(1.17) var(--backgroundModule, $body_bg),
                        0 0 0 rem(2) $color-white;
                }
                
                &:hover .swatch-circle {
                    box-shadow:
                        0 0 0 rem(1.17) var(--backgroundModule, $body_bg),
                        0 0 0 rem(2) $link-decoration-color-on-dark;
                    transition: box-shadow $duration-default ease;
                }
            }
        }
    }

    &.on-color-light {
        .product {
            .size-btn.selected span {
                color: $dark-text;
                border-color: $link-decoration-color-on-light;
            }
        }
    }
}

.hero-asset {
    $root: &;
    $image-background: $home-image-bg-color;

    display: grid;
    grid-gap: $page-grid-gutter;
    grid-template-columns: [grid-start] repeat($page-grid-columns, 1fr) [grid-end];
    margin: 0 auto;
    max-width: $site-max-width;
    position: relative;

    @include media-breakpoint-down(sm) {
        grid-template-columns: [grid-start] repeat($page-grid-columns-sm, 1fr) [grid-end];
    }

    &.full-bleed {
        @media screen and (min-width: $page-grid-max-width) {
            grid-template-columns: [grid-start] repeat($page-grid-columns, rem(116)) [grid-end];
        }
    }

    &-link-wrapper {
        inset: 0;
        position: absolute;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &-picture,
    .video-component {
        aspect-ratio: 16 / 9;
        background: $image-background;
        display: block;
        grid-column: grid-start / grid-end;
        grid-row: 1 / 2;
        overflow: hidden;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(md) {
            aspect-ratio: 2 / 3;
        }

        @include media-breakpoint-down(sm) {
            aspect-ratio: 9 / 16;
        }

        @include media-breakpoint-up(lg) {
            min-height: rem(800);

            &::after {
                background: linear-gradient(2deg, rgba(0, 0, 0, 0.7) 5.38%, rgba(0, 0, 0, 0) 64.94%);
                bottom: 0;
                content: '';
                height: rem(500);
                left: 0;
                pointer-events: none;
                position: absolute;
                right: 0;
            }
        }

        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            content: '';
            height: rem(200);
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: $zindex-lowest;

            @include media-breakpoint-up(lg) {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
                height: rem(220);
            }
        }

        video,
        img {
            border: none;
            color: $image-background;
            display: block;
            height: 100%;
            object-fit: cover;
            transition: transform $duration-slow ease-in-out;
            width: 100%;
        }
    }

    &.full-bleed {
        .hero-asset-picture,
        .video-component {
            @media screen and (min-width: $page-grid-max-width) {
                width: 100vw;
                position: relative;
                left: calc((100vw - $page-grid-max-width) / -2);
            }
        }
    }

    .video-controls {
        z-index: $zindex-medium;
        flex-direction: row;

        button {
            padding: rem(10);

            &:focus-visible {
                outline-color: rgba($white, 0.4);
            }
        }

        svg {
            width: rem(24);
            height: rem(24);
        }
    }

    .hero-banner-caption {
        display: contents;
    }

    &-picture + .hero-banner-caption,
    .video-component + .hero-banner-caption {
        @include media-breakpoint-up(lg) {
            color: $light-text;
            margin-bottom: $space-4xl;
        }
    }

    &-caption {
        color: inherit;
        grid-column: 4 / 9;
        grid-row: 1 / 2;
        margin: auto 0 $space-xl;
        position: relative;
        text-align: left;
        z-index: $zindex-lowest;

        @include media-breakpoint-down(md) {
            grid-column: 2 / 11;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1 / 7;
            padding-inline: $space-l;
        }

        .hero-banner-caption & {
            @include media-breakpoint-down(md) {
                padding-top: $space-3xl;
                grid-row: 2 / 2;
            }
        }
    }

    &-title {
        @include heading-h2;

        margin: 0;

        @include media-breakpoint-down(md) {
            color: $dark-text;
        }
    }

    &-description {
        @include body-14-light;

        margin: $space-m 0 0;
    }

    &-cta {
        color: inherit;
        margin: $space-xs 0 0;

        &:hover {
            color: inherit;
        }

        & + & {
            margin-left: $space-m;
        }

        &:focus-visible {
            outline-color: inherit;
        }
    }

    &.m-caption-top {
        margin-top: $space-xl;

        .hero-banner-caption {
            color: $dark-text;
        }

        .hero-asset-picture,
        .video-component {
            grid-row: 3 / 3;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    &.m-caption-transfered {
        .hero-asset-caption {
            @include media-breakpoint-down(md) {
                margin-bottom: (-$space-2xl);
            }
        }
    }
}

.m-emphasized-logo {
    .hero-asset-caption {
        grid-column: 2 / 7;

        @include media-breakpoint-only(md) {
            grid-column: 2/11;
        }

        @include media-breakpoint-down(sm) {
            grid-column: 1 / 7;
        }
    }
}

@mixin button(
    $bg: $white,
    $color: $black,
    $border-color: $black,
    $hover-bg: $white,
    $hover-color: $black,
    $hover-border-color: $black,
    $active-bg: $white,
    $active-color: $black,
    $active-border-color: $black
) {
    @if $bg {
        background-color: $bg;
    }
    @if $color {
        color: $color;
    }
    @if $border-color {
        border-color: $border-color;
    }

    &:hover,
    &:focus {
        @if $hover-bg {
            background-color: $hover-bg;
        }
        @if $hover-color {
            color: $hover-color;
        }
        @if $hover-border-color {
            border-color: $hover-border-color;
        }
    }

    &:active {
        @if $active-bg {
            background-color: $active-bg;
        }
        @if $active-color {
            color: $active-color;
        }
        @if $active-border-color {
            border-color: $active-border-color;
        }
    }
}


@mixin link-underline {
    text-decoration: underline;
    text-decoration-color: $link-decoration-color;
    text-underline-offset: rem(1); // should be 4px by designs, but it overlaps next line on text wrap
    text-underline-position: under;
    transition:
        color 0.1s linear,
        text-decoration-color 0.1s linear;

    &:hover {
        text-decoration-color: inherit;
    }

    &:active {
        text-decoration-color: $link-decoration-color;
    }
}

.modal-content,
.sheet-dialog {
    .modal-title {
        @include heading-h2;

        @include media-breakpoint-up(lg) {
            @include font-serif-32px;
        }
    }

    .pickup-in-store-content {
        .modal-body {
            @include media-breakpoint-up(md) {
                padding: $space-m $space-4xl;
            }
        }

        .modal-footer {
            @include media-breakpoint-up(md) {
                padding-inline: $space-4xl;
            }
        }

        .modal-footer-inner {
            margin: 0;
        }
    }

    .pickup-in-store-product {
        display: flex;
        margin-bottom: $space-l;

        .pickup-product-img {
            @include product-image;

            flex-basis: rem(80);
            height: rem(100);
            margin-right: $space-l;
            min-width: rem(80);

            .no-images {
                padding: $space-s;
            }
        }

        .product-desc {
            .pickup-product-name,
            .pickup-price,
            .pickup-color {
                @include body-14-light;

                margin-bottom: 0;
            }
        }

        .size-container {
            $swatch-size: rem(28);

            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-6);

            .size-list {
                margin-right: $space-xs;

                .size-btn {
                    @include font-sans-light;
                    @include font-sans-14px;

                    color: $dark-text;
                    height: $swatch-size;
                    min-width: $swatch-size;
                    padding: 0 rem(6);
                    position: relative;
                    text-align: left;
                    text-decoration: none;

                    span {
                        border-bottom: rem(1) solid transparent;
                        padding-bottom: rem(2);
                    }

                    &:hover {
                        span {
                            color: $base-text-color;
                            border-bottom-color: $border-color-med;
                        }
                    }

                    &.not-available,
                    &[disabled] {
                        text-decoration: line-through;
                        opacity: 0.5;

                        &:hover span {
                            color: $dark-text;
                            border-bottom-color: transparent;
                        }
                    }

                    &.not-available.selected,
                    &.selected {
                        span {
                            border-bottom-color: $dark-text;
                        }
                    }

                    &.btn {
                        text-transform: none;
                    }
                }
            }
        }
    }

    .ispu-cart-conflict {
        @include body-12-regular;

        color: $error-text-color;
        margin-bottom: $space-m;

        .unavailable-message {
            margin-bottom: $space-s;
        }

        .unavailable-list {
            list-style: inside;

            li {
                list-style-type: initial;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .pickup-product-img {
            flex-basis: 25%;
            margin-right: $space-xl;
        }

        .pickup-in-store-product {
            .product-desc {
                @include body-14-light;

                .pickup-product-name {
                    margin-bottom: 0;
                }
            }
        }
    }

    .pickup-in-store-container {
        @include font-sans-regular;

        color: $base-text-color;
        margin-top: $space-2xl;

        .find-store-input-group.form-group {
            margin-bottom: $space-s;
            position: relative;
        }

        .find-store-input {
            padding-right: rem(126);

            .cart-page & {
                @include media-breakpoint-down(sm) {
                    padding-right: rem(80);
                }
            }


            &-label {
                @include media-breakpoint-down(sm) {
                    max-width: calc(100% - #{rem(126)});
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .cart-page & {
                    @include media-breakpoint-down(sm) {
                        max-width: calc(100% - #{rem(80)});
                    }
                }
            }
        }

        .find-store-submit {
            @include font-sans-medium;
            background: transparent;
            border: none;
            height: 100%;
            margin-bottom: 0;
            padding: 0 $space-m 0 0;
            position: absolute;
            right: 0;
            text-align: left;
            top: 0;
            width: auto;
            color: $base-text-color;

            &:hover {
                background: transparent;
            }

            &:disabled {
                background: transparent;
                border: none;
            }
        }

        .detect-location {
            @include body-14-light;
            @include link-underline;

            width: fit-content;

            &-text {
                pointer-events: none;
            }
        }

        .preferred-store {
            .store-results-header {
                display: flex;
                align-items: center;
                gap: $space-s;
            }

            .store-status {
                color: $base-text-color;
            }
        }
    }

    .store-locator-no-results {
        @include body-14-light;

        margin-bottom: $space-xl;
        word-break: break-word;
    }

    .preferred-store-no-results {
        @include body-14-light;

        margin: $space-xl 0;
    }

    .preferred-store {
        .store {
            border-color: $preferred-store-border-color;
            flex-wrap: wrap;
        }

        .store-results-header {
            @include font-sans-medium;

            align-items: center;
            display: flex;
            gap: $space-s;
            margin: 0;
            width: 100%;

            .store-status {
                color: $base-text-color;
            }
        }
    }

    .form-preferred-store-group {
        display: none;
        margin: $space-s 0 0;

        .custom-checkbox .custom-control-input:checked {
            ~ .custom-control-label:after {
                left: rem(-34);
            }
        }

        .preferred-store-input-label {
            font-size: rem(14);
        }
    }

    .detect-location {
        @include body-12-regular;
        @include link-underline;

        border: 0;
        padding: 0;
        position: relative;
        margin-bottom: $space-m;

        &-text {
            pointer-events: none;
        }

        .geolocation-activated,
        .geolocation-not-activated {
            position: absolute;
            right: rem(-24);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .store {
        border: rem(1) solid $store-border-color;
        margin-bottom: $space-m;
        padding: $space-l;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:has(.custom-control-input:checked) {
            border-color: $preferred-store-border-color;
        }

        .store-details {
            margin-right: $space-xl;
            max-width: calc(100% - rem(45));
        }

        .store-status {
            @include body-12-light;

            max-width: rem(304);
        }
    }

    .select-store-input-group {
        --custom-control-line-height: #{rem(18)};

        display: flex;
        padding: 0;

        .custom-control-input {
            z-index: $zindex-negative;
        }
    }

    .select-store-input-label {
        cursor: pointer;
        height: rem(18);
        width: rem(18);

        &::before,
        &::after {
            border-radius: rem(1);
            height: rem(18);
            left: 0;
            width: rem(18);
        }
    }

    .custom-control-input:disabled {
        ~ .custom-control-label {
            cursor: default;

            &:before {
                border-color: transparent;
            }
        }
    }

    .store-accordion {
        @include body-12-light;

        .store-details-card {
            border: none;

            .store-card-header {
                @include font-sans-light;

                border-bottom: none;
                padding: 0;
                margin-bottom: 0;
            }

            .store-btn-link {
                @include body-12-light;

                display: flex;
                padding: 0;
                text-transform: none;

                &::after {
                    background: $icon-chevron-grey;
                    border: 0;
                    height: rem(12);
                    margin: $space-xs 0 0;
                    position: static;
                    transform: rotate(180deg);
                    width: rem(12);
                }

                .collapsed,
                .expanded {
                    pointer-events: none;
                    padding-right: rem(5);
                }
            }

            .btn[aria-expanded='true'] {
                &:after {
                    transform: rotate(180deg) scaleY(-1);
                }
            }

            .store-open-hours {
                padding-left: $space-xs;
            }

            .store-card-body {
                @include body-12-light;

                padding: $space-s 0 0 0;

                .store-card-link {
                    @include body-12-light;
                    @include link-underline;
                }
            }

            .store-hours-container {
                margin-bottom: $space-s;
            }

            .store-hours-list-item {
                @include font-sans-light;

                line-height: rem(22);

                &.today {
                    @include font-sans-regular;
                    color: $store-results-heading;
                }
            }
        }
    }

    .store-name,
    .storelocator-phone,
    .store-distance,
    .store-address {
        @include body-14-light;
    }

    .store-name {
        color: $store-results-heading;
    }

    .store-address {
        margin-bottom: $space-s;
    }

    .storelocator-phone {
        display: none;
    }

    .store-call-icon {
        margin-right: $space-s;
    }

    .store-current-day-status {
        margin-bottom: $space-s;
    }

    .store-hours-list-item {
        display: flex;
        justify-content: space-between;

        div {
            &:nth-of-type(1) {
                min-width: rem(74);
                margin-right: $space-m;
            }

            &:nth-of-type(2) {
                width: calc(70% - $space-m);
            }
        }

        &.today {
            @include font-sans-regular;
        }
    }

    .store-stock-status {
        @include body-14-light;

        align-items: center;
        color: $store-results-heading;
        display: flex;
        margin-bottom: $space-s;

        &-indicator {
            border-radius: 50%;
            height: rem(8);
            margin-right: $space-s;
            width: rem(8);

            &-available {
                background: $store-available;
            }

            &-unavailable {
                border: rem(1) solid $store-unavailable;
            }

            &-limited {
                background: $store-limited;
            }
        }
    }

    .geo-location-link {
        @include body-12-light;
        @include link-underline;

        display: inline-block;
        margin-top: $space-m;
    }

    .find-stores-form,
    .store-search-form {
        .form-group {
            margin-top: 0;
            margin-bottom: $space-s;
        }
    }

    .find-store-submit,
    .btn-store-search {
        margin-bottom: $space-2xl;
    }

    .store-results-header,
    .stores-count-message {
        @include body-14-light;

        color: $store-results-heading;
        margin-bottom: $space-l;
    }

    .store-results-header {
        margin-top: $space-2xl;

        .store-locator-no-results {
            margin: $space-2xl 0 0;
        }
    }

    .store-results-header .search-store-toggle {
        @include body-14-light;

        color: $store-toggle-color;
        margin-left: $space-s;
    }

    .pick-up-confirm-section {
        margin-top: $space-3xl;

        .store-accordion {
            margin-bottom: 0;
        }
    }

    .change-location {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $space-m;

        .change-location-link {
            @include body-12-light;
            @include link-underline;
        }
    }

    .geo-location-error {
        @include body-12-light;

        margin-bottom: $space-m;
        color: $geolocation-error-color;
    }

    .store-postal-code::placeholder {
        opacity: 0;
    }

    .store-postal-code:focus {
        &::placeholder {
            opacity: 1;
        }
    }

    @include media-breakpoint-up(md) {
        .find-stores-form,
        .store-search-form {
            .form-group {
                margin-bottom: $space-m;
            }
        }

        .store {
            .store-status {
                max-width: initial;
            }
        }

        .store-current-day-status {
            padding: $space-2xs 0;
            margin-bottom: 0;
        }

        .stock-status {
            @include body-14-regular;

            padding: $space-2xs 0;
        }

        .change-location {
            margin-bottom: $space-3xl;
        }
    }

    .preferred-store {
        &-container {
            max-height: 100vh;

            @include media-breakpoint-down(sm) {
                overflow-y: auto;
            }
        }

        &-content {
            .results-card {
                @include media-breakpoint-up(md) {
                    padding-bottom: $space-2xl;
                }
            }
        }
    }
}

// global classes used on multiple pages:
.availability-block {
    display: block;
    margin: $space-3xl 0 $space-2xl;

    .msg-text {
        margin-right: $space-xs;
    }

    .m-no-border {
        border: none;
    }

    .available {
        align-items: center;
        display: flex;

        &::before {
            background: $store-available;
            border-radius: 50%;
            content: '';
            display: block;
            height: rem(8);
            margin-right: $space-s;
            width: rem(8);
        }

        &.limited::before {
            background: $store-limited;
        }
    }

    .unavailable {
        align-items: center;
        display: flex;

        &::before {
            border: rem(1) solid $store-unavailable;
            border-radius: 50%;
            content: '';
            display: block;
            height: rem(8);
            margin-right: $space-s;
            width: rem(8);
        }
    }

    .availability-change-store-link {
        @include body-14-light;

        color: $color-grey-dark;
        display: block;
        cursor: pointer;
        text-decoration: none;

        .tertiary-link,
        .msg-text {
            white-space: nowrap;
        }

        .msg-text,
        .msg-text::before {
            display: inline-block;
        }

        &:hover {
            color: $color-black;

            .tertiary-link,
            .msg-text {
                color: $color-black;
            }
        }

        .lp-icon-chevron {
            position: relative;

            &::before {
                height: rem(5);
                position: absolute;
                right: rem(-9);
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: rem(5);
            }
        }
    }
}
